import './App.css';
import DiceGame from './DiceGame';

function App() {
  return (
    <DiceGame />
  );
}

export default App;

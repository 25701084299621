import React, { useState, useEffect } from 'react';
import { Dice1, Dice2, Dice3, Dice4, Dice5, Dice6, Timer, History, Trash2, Download } from 'lucide-react';
import { Hexagon } from 'lucide-react'; // We'll use this for custom dice

const DiceGame = () => {
  const [numDice, setNumDice] = useState(1);
  const [diceValues, setDiceValues] = useState([1]);
  const [isRolling, setIsRolling] = useState(false);
  const [animatingValues, setAnimatingValues] = useState([1]);
  const [autoRollInterval, setAutoRollInterval] = useState(0);
  const [autoRollActive, setAutoRollActive] = useState(false);
  const [rollHistory, setRollHistory] = useState([]);
  const [diceType, setDiceType] = useState(6); // Default to d6

  const diceTypes = [4, 6, 8, 10, 12, 20];

  const DiceIcons = {
    1: Dice1,
    2: Dice2,
    3: Dice3,
    4: Dice4,
    5: Dice5,
    6: Dice6
  };

  // Load history from localStorage on component mount
  useEffect(() => {
    const savedHistory = localStorage.getItem('diceRollHistory');
    if (savedHistory) {
      setRollHistory(JSON.parse(savedHistory));
    }
  }, []);

  // Save history to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('diceRollHistory', JSON.stringify(rollHistory));
  }, [rollHistory]);

  useEffect(() => {
    let animationInterval;
    if (isRolling) {
      animationInterval = setInterval(() => {
        setAnimatingValues(prev => 
          prev.map(() => Math.floor(Math.random() * diceType) + 1)
        );
      }, 50);

      setTimeout(() => {
        clearInterval(animationInterval);
        setIsRolling(false);
        setAnimatingValues(diceValues);
      }, 600);
    }
    return () => clearInterval(animationInterval);
  }, [isRolling, diceValues, diceType]);

  useEffect(() => {
    let autoRollTimer;
    if (autoRollInterval > 0 && autoRollActive) {
      autoRollTimer = setInterval(() => {
        if (!isRolling) {
          rollDice();
        }
      }, autoRollInterval * 1000);
    }
    return () => clearInterval(autoRollTimer);
  }, [autoRollInterval, autoRollActive, isRolling]);

  const rollDice = () => {
    if (!isRolling) {
      setIsRolling(true);
      const newValues = Array(numDice)
        .fill(0)
        .map(() => Math.floor(Math.random() * diceType) + 1);
      setDiceValues(newValues);
      
      // Add roll to history
      const rollResult = {
        timestamp: new Date().toISOString(),
        values: newValues,
        total: newValues.reduce((sum, val) => sum + val, 0),
        average: (newValues.reduce((sum, val) => sum + val, 0) / newValues.length).toFixed(1),
        numDice: numDice,
        diceType: diceType
      };
      setRollHistory(prev => [rollResult, ...prev]);
    }
  };

  const clearHistory = () => {
    setRollHistory([]);
    localStorage.removeItem('diceRollHistory');
  };

  const exportHistory = () => {
    const exportData = {
      exportDate: new Date().toISOString(),
      totalRolls: rollHistory.length,
      rolls: rollHistory
    };

    const jsonString = JSON.stringify(exportData, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = url;
    link.download = `dice-roll-history-${new Date().toLocaleDateString().replace(/\//g, '-')}.json`;
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleNumDiceChange = (e) => {
    const value = parseInt(e.target.value);
    setNumDice(value);
    setDiceValues(Array(value).fill(1));
    setAnimatingValues(Array(value).fill(1));
  };

  const handleDiceTypeChange = (e) => {
    const value = parseInt(e.target.value);
    setDiceType(value);
    setDiceValues(Array(numDice).fill(1));
    setAnimatingValues(Array(numDice).fill(1));
  };

  const handleAutoRollChange = (e) => {
    const value = parseInt(e.target.value);
    setAutoRollInterval(value);
    setAutoRollActive(value > 0);
  };

  const diceTotal = animatingValues.reduce((sum, value) => sum + value, 0);
  const diceAverage = (diceTotal / numDice).toFixed(1);

  const getGridCols = (numDice) => {
    if (numDice <= 5) return 'grid-cols-5';
    if (numDice <= 10) return 'grid-cols-5';
    if (numDice <= 20) return 'grid-cols-10';
    return 'grid-cols-12';
  };

  const renderDiceIcon = (value) => {
    if (diceType === 6 && DiceIcons[value]) {
      const DiceIcon = DiceIcons[value];
      return <DiceIcon size={numDice > 20 ? 32 : 40} className="text-blue-500" />;
    }
    return (
      <div className="relative">
        <Hexagon size={numDice > 20 ? 32 : 40} className="text-blue-500" />
        <span className="absolute inset-0 flex items-center justify-center text-sm font-bold">
          {value}
        </span>
      </div>
    );
  };

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <div className="mb-6 space-y-4 relative z-10 bg-white">
        <h1 className="text-2xl font-bold text-center">RPG Dice Roller</h1>
        
        <div className="flex items-center justify-center gap-6 flex-wrap">
          <div className="flex items-center gap-4">
            <label className="font-medium">Dice Type:</label>
            <select
              value={diceType}
              onChange={handleDiceTypeChange}
              className="border rounded px-3 py-1 bg-white"
              disabled={isRolling}
            >
              {diceTypes.map(type => (
                <option key={type} value={type}>
                  d{type}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center gap-4">
            <label className="font-medium">Number of Dice:</label>
            <select
              value={numDice}
              onChange={handleNumDiceChange}
              className="border rounded px-3 py-1 bg-white"
              disabled={isRolling}
            >
              {[...Array(100)].map((_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <Timer size={20} className="text-gray-600" />
              <label className="font-medium">Auto-roll every:</label>
            </div>
            <select
              value={autoRollInterval}
              onChange={handleAutoRollChange}
              className="border rounded px-3 py-1 bg-white"
            >
              <option value="0">Disabled</option>
              {[...Array(10)].map((_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1} second{i !== 0 ? 's' : ''}
                </option>
              ))}
            </select>
          </div>
        </div>
        
        <div className="flex flex-wrap justify-center gap-4 p-4">
          <button
            onClick={rollDice}
            disabled={isRolling}
            className={`px-6 py-2 font-bold rounded transition-colors ${
              isRolling 
                ? 'bg-gray-400 cursor-not-allowed' 
                : 'bg-blue-500 hover:bg-blue-600 text-white'
            }`}
          >
            {isRolling ? 'Rolling...' : 'Roll Dice'}
          </button>
          
          <div className="flex gap-4">
            <div className="bg-gray-100 px-4 py-2 rounded flex items-center gap-2">
              <span className="text-gray-600">Total:</span>
              <span className="font-medium">{diceTotal}</span>
            </div>
            
            <div className="bg-gray-100 px-4 py-2 rounded flex items-center gap-2">
              <span className="text-gray-600">Average:</span>
              <span className="font-medium">{diceAverage}</span>
            </div>
          </div>
        </div>
      </div>

      <div className={`grid ${getGridCols(numDice)} gap-3 p-5 justify-items-center overflow-y-auto max-h-[40vh] relative`}>
        {animatingValues.map((value, index) => (
          <div
            key={index}
            className={`p-2 bg-white rounded-lg shadow-md hover:shadow-lg 
              ${isRolling ? 'animate-bounce' : 'transition-shadow'}
            `}
          >
            {renderDiceIcon(value)}
          </div>
        ))}
      </div>

      {/* Roll History Section */}
      <div className="mt-8 bg-white rounded-lg shadow-md p-4">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
            <History size={20} className="text-gray-600" />
            <h2 className="text-xl font-bold">Roll History</h2>
          </div>
          <div className="flex gap-2">
            {rollHistory.length > 0 && (
              <>
                <button
                  onClick={exportHistory}
                  className="flex items-center gap-2 px-4 py-2 text-green-600 hover:bg-green-50 rounded transition-colors"
                  title="Export history as JSON"
                >
                  <Download size={16} />
                  Export
                </button>
                <button
                  onClick={clearHistory}
                  className="flex items-center gap-2 px-4 py-2 text-red-600 hover:bg-red-50 rounded transition-colors"
                >
                  <Trash2 size={16} />
                  Clear
                </button>
              </>
            )}
          </div>
        </div>
        
        <div className="overflow-y-auto max-h-[30vh]">
          {rollHistory.length === 0 ? (
            <p className="text-gray-500 text-center py-4">No rolls yet</p>
          ) : (
            <div className="space-y-2">
              {rollHistory.map((roll, index) => (
                <div
                  key={roll.timestamp}
                  className="flex items-center justify-between p-3 bg-gray-50 rounded hover:bg-gray-100 transition-colors"
                >
                  <div className="flex items-center gap-4">
                    <span className="text-gray-500 text-sm">
                      {new Date(roll.timestamp).toLocaleTimeString()}
                    </span>
                    <span className="text-sm text-gray-600">
                      {roll.numDice}d{roll.diceType}:
                    </span>
                    <span className="font-medium">
                      [{roll.values.join(', ')}]
                    </span>
                  </div>
                  <div className="flex gap-4 text-sm">
                    <span className="text-gray-600">
                      Total: <span className="font-medium">{roll.total}</span>
                    </span>
                    <span className="text-gray-600">
                      Average: <span className="font-medium">{roll.average}</span>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DiceGame;